import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header-image header-image--on" style={{"backgroundImage":"url(/images/demo/demo-landscape.jpg)"}}></div>
    <div className="header-image"></div>
    <div className="header-overlay"></div>
    <div className="header__content">
      <Link to="/" className="header__title">
      Journal
      </Link>
      <p className="header__tagline">A stunning personal blog Jekyll theme with an image-focused design.</p>
      <div className="menu">
        <div className="menu__toggle js-menu-toggle">
        <div className="menu__toggle__icon"><span></span></div>
      </div>
      <div className="menu__wrap">
        <ul className="menu__list">
        <li className="menu__list__item">
          <Link to="/" className="menu__list__item__link">Home</Link>
        </li>
        <li className="menu__list__item">
          <Link to="/blog/" className="menu__list__item__link">Blog</Link>
        </li>
        <li className="menu__list__item">
          <Link to="/illustrations/" className="menu__list__item__link">Illustrations</Link>
        </li>
        <li className="menu__list__item">
          <Link to="/about" className="menu__list__item__link">About</Link>
        </li>
        <li className="menu__list__item">
          <Link to="/contact" className="menu__list__item__link">Contact</Link>
        </li>
        </ul>
        <ul className="socials">
        </ul>
      </div>
    </div>
    </div>
  </header>
  )
  
  Header.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Header.defaultProps = {
    siteTitle: ``,
  }
  
  export default Header
  