import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import DemoPic from "../../images/demo-square.jpg"

export default function DemoPost() {
  return (
    <Layout>
      <div
        className="page__content"
        data-page-title="Demo Post – Journal"
        data-image=""
      >
        <section className="intro">
          <div className="wrap">
            <h1>Demo Post</h1>
            <p>29 June 2018</p>
          </div>
        </section>
        <section className="single">
          <div className="wrap">
            <p>
              <img src={DemoPic} alt="" />
            </p>
            <h2 id="demo-content">Demo content</h2>
            <p>
              This page is a demo that shows everything you can do inside
              portfolio and blog posts.
            </p>
            <p>
              We’ve included everything you need to create engaging posts about
              your work, and show off your case studies in a beautiful way.
            </p>
            <p>
              <strong>Obviously,</strong> we’ve styled up
              <em>all the basic</em> text formatting options
              <Link to="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">
                available in markdown
              </Link>
              .
            </p>
            <p>You can create lists:</p>
            <ul>
              <li>Simple bulleted lists</li>
              <li>Like this one</li>
              <li>Are cool</li>
            </ul>
            <p>And:</p>
            <ol>
              <li>Numbered lists</li>
              <li>Like this other one</li>
              <li>Are great too</li>
            </ol>
            <p>
              You can also add blockquotes, which are shown at a larger width to
              help break up the layout and draw attention to key parts of your
              content:
            </p>
            <blockquote>
              <p>
                “Simple can be harder than complex: You have to work hard to get
                your thinking clean to make it simple. But it’s worth it in the
                end because once you get there, you can move mountains.”
              </p>
            </blockquote>
            <p>The theme also supports markdown tables:</p>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Author</th>
                  <th>Supports tables?</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Duet Jekyll Theme</td>
                  <td>Jekyll Themes</td>
                  <td>Yes</td>
                  <td>$49</td>
                </tr>
                <tr>
                  <td>Index Jekyll Theme</td>
                  <td>Jekyll Themes</td>
                  <td>Yes</td>
                  <td>$49</td>
                </tr>
                <tr>
                  <td>Journal Jekyll Theme</td>
                  <td>Jekyll Themes</td>
                  <td>Yes</td>
                  <td>$49</td>
                </tr>
              </tbody>
            </table>
            <p>
              And footnotes
              <sup id="fnref:1">
                <Link to="#fn:1" className="footnote">
                  1
                </Link>
              </sup>
              , which link to explanations
              <sup id="fnref:2">
                <Link to="#fn:2" className="footnote">
                  2
                </Link>
              </sup>{" "}
              at the bottom of the page
              <sup id="fnref:3">
                <Link to="#fn:3" className="footnote">
                  3
                </Link>
              </sup>
              .
            </p>
            <p>You can throw in some horizontal rules too:</p>
            <hr />
            <h3 id="image-galleries">Image galleries</h3>
            <p>Here’s a really neat custom feature we added – galleries:</p>
            <div className="gallery" data-columns="3">
              <img src="/images/demo/demo-portrait.jpg" />
              <img src="/images/demo/demo-landscape.jpg" />
              <img src="/images/demo/demo-square.jpg" />
              <img src="/images/demo/demo-landscape-2.jpg" />
            </div>
            <p>
              Inspired by the Galleries feature from WordPress, we’ve made it
              easy to create grid layouts for your images. Just use a bit of
              simple HTML in your post to create a masonry grid image layout:
            </p>
            <div className="language-html highlighter-rouge">
              <div className="highlight">
                <pre className="highlight">
                  <code>
                    <span className="nt">&lt;div</span>
                    <span className="na">className=</span>
                    <span className="s">"gallery"</span>
                    <span className="na">data-columns=</span>
                    <span className="s">"3"</span>
                    <span className="nt">&gt;</span>
                    <span className="nt">&lt;img</span>
                    <span className="na">src=</span>
                    <span className="s">"/images/demo/demo-portrait.jpg"</span>
                    <span className="nt">/&gt;</span>
                    <span className="nt">&lt;img</span>
                    <span className="na">src=</span>
                    <span className="s">"/images/demo/demo-landscape.jpg"</span>
                    <span className="nt">/&gt;</span>
                    <span className="nt">&lt;img</span>
                    <span className="na">src=</span>
                    <span className="s">"/images/demo/demo-square.jpg"</span>
                    <span className="nt">/&gt;</span>
                    <span className="nt">&lt;img</span>
                    <span className="na">src=</span>
                    <span className="s">
                      "/images/demo/demo-landscape-2.jpg"
                    </span>
                    <span className="nt">/&gt;</span>
                    <span className="nt">&lt;/div&gt;</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>
              <em>
                See what we did there? Code and syntax highlighting is built-in
                too!
              </em>
            </p>
            <p>
              Change the number inside the ‘columns’ setting to create different
              types of gallery for all kinds of purposes. You can even click on
              each image to seamlessly enlarge it on the page.
            </p>
            <hr />
            <h3 id="image-carousels">Image carousels</h3>
            <p>
              Here’s another gallery with only one column, which creates a
              carousel slide-show instead.
            </p>
            <p>
              A nice little feature: the carousel only advances when it is in
              view, so your visitors won’t scroll down to find it half way
              through your images.
            </p>
            <div className="gallery" data-columns="1">
              <img src="/images/demo/demo-landscape.jpg" />
              <img src="/images/demo/demo-landscape-2.jpg" />
            </div>
            <h3 id="what-about-videos">What about videos?</h3>
            <p>
              Videos are an awesome way to show off your work in a more engaging
              and personal way, and we’ve made sure they work great on our
              themes. Just paste an embed code from YouTube or Vimeo, and the
              theme makes sure it displays perfectly:
            </p>
            <div className="video-wrap">
              <div className="video" style={{ paddingBottom: "45%" }}>
                <iframe
                  src="https://player.vimeo.com/video/203710832"
                  width="640"
                  height="288"
                  frameBorder="0"
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allowFullScreen=""
                ></iframe>
              </div>
            </div>
            <hr />
            <h2 id="pretty-cool-huh">Pretty cool, huh?</h2>
            <p>
              We’ve packed this theme with powerful features to show off your
              work. Why not put them to use on your new portfolio?
            </p>
            <p>
              <Link
                to="https://jekyllthemes.io/theme/journal-personal-jekyll-theme"
                className="button button--large"
              >
                Get This Theme
              </Link>
            </p>
            <div className="footnotes">
              <ol>
                <li id="fn:1">
                  <p>
                    Beautiful modern, minimal theme design.
                    <Link to="#fnref:1" className="reversefootnote">
                      &#8617;
                    </Link>
                  </p>
                </li>
                <li id="fn:2">
                  <p>
                    Powerful features to show off your work.
                    <Link to="#fnref:2" className="reversefootnote">
                      &#8617;
                    </Link>
                  </p>
                </li>
                <li id="fn:3">
                  <p>
                    Maintained and supported by the theme developer.
                    <Link to="#fnref:3" className="reversefootnote">
                      &#8617;
                    </Link>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
